import { lazy } from "react";

import type {
  GameArticleNonComponent,
  GameDefinition,
} from "@/app/game-definition.mjs";
import gamesList from "@/app/games.mjs";
import { GAME_SYMBOL_MINECRAFT } from "@/app/upcoming-game-definitions.mjs";
import { NEWS_GAMES_LIST } from "@/data-models/articles.mjs";
import { hasCMSAccess } from "@/feature-cms/utils.mjs";
import { ALLOW_LIST, SEARCH_PARAMS } from "@/feature-news/constants.mjs";
import {
  getArticle,
  getArticlePreview,
  getLatestNews,
} from "@/feature-news/fetches.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import routes from "@/routes/routes.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  gamesList,
});
const originalRoutes = {};

// Settings
const MULTI_ARTICLES = [GAME_SYMBOL_FORTNITE, GAME_SYMBOL_MINECRAFT];

// RegExp
const REGEXP_NEWS_ROUTES = new RegExp(
  `^\\/(?:${NEWS_GAMES_LIST.join("|")})(?:\\/)?$`,
  "i",
);
const REGEXP_PATCHNOTES_ROUTES = new RegExp(
  `^\\/(?:${NEWS_GAMES_LIST.join("|")})\\/patchnotes\\/latest(?:\\/)?$`,
  "i",
);
const REGEXP_LATEST_URL = /\/patchnotes(?:\/)?(?:latest)?(?:\/)?$/i;

export function setup() {
  for (const i of routes) {
    if (typeof i.path !== "string") continue;
    // Add article preview data fetching to `/{game}` route
    if (REGEXP_NEWS_ROUTES.test(i.path)) {
      originalRoutes[i.path] = { ...i };
      // Intercept fetchData
      const path = i.path as string;
      const prev = i.fetchData;
      const next = async function fetchData(...args): Promise<void> {
        await Promise.all([
          // @ts-ignore Argument passthrough
          prev?.(...args) ?? Promise.resolve(),
          (async (): Promise<void> => {
            const game = path.match(/\w+/)?.[0];
            if (!game) return;
            const latest = await getLatestNews({
              isStaging: hasCMSAccess(args[1]),
            });
            const hash = latest[game];
            if (Array.isArray(hash)) {
              await Promise.all(hash.map((h) => getArticlePreview(h)));
            } else if (typeof hash === "string") {
              await getArticlePreview(hash);
            }
          })(),
        ]);
      };
      i.fetchData = next;
      continue;
    }
    // Add or update patch notes route `/${game}/patchnotes/latest`
    if (REGEXP_PATCHNOTES_ROUTES.test(i.path)) {
      originalRoutes[i.path] = { ...i };
      const path = i.path as string;
      const prev = i.fetchData;
      const next = async function fetchData(...args): Promise<void> {
        await Promise.all([
          // @ts-ignore Argument passthrough
          prev?.(...args) ?? Promise.resolve(),
          (async (): Promise<void> => {
            const game = path.match(/^\/(\w+)\//)?.[1];
            if (!game) return;
            const latest = await getLatestNews({
              isStaging: hasCMSAccess(args[1]),
            });
            const struct = latest[game];
            const articleId = Array.isArray(struct)
              ? struct[
                  sanitizeNumber(
                    Number(
                      (args[1] as URLSearchParams)?.get(SEARCH_PARAMS.INDEX),
                    ),
                  )
                ]
              : struct;
            if (typeof articleId !== "string") return;
            await Promise.all([
              getArticle(articleId),
              getArticlePreview(articleId),
            ]);
          })(),
        ]);
      };
      i.fetchData = next;
      i.component = () => import("@/feature-news/Article.jsx");
    }
  }
  // Add or update article preview
  const gamesListNext = gamesList.reduce((acc, cur) => {
    const game: GameDefinition<typeof cur.gameSymbol> = cur;
    if (!ALLOW_LIST.includes(game.gameSymbol)) {
      acc.push(game);
      return acc;
    }
    const meta = { ...game.meta };
    const articles = game?.meta?.articles?.slice() ?? [];
    const index = articles.findIndex(
      (i: GameArticleNonComponent) =>
        typeof i.link?.href === "string" && REGEXP_LATEST_URL.test(i.link.href),
    );
    const isMultipleArticles = MULTI_ARTICLES.some(
      (i) => i === game.gameSymbol,
    );
    const preview = {
      Component: lazy(() => import("@/feature-news/ArticlePreview.jsx")),
      componentProps: {
        game: game.gameSymbol,
        // If this is apart of a multi-article preview page
        ...(isMultipleArticles ? { index: 0 } : {}),
      },
    };
    const shouldReplace = index >= 0;
    Array.prototype[shouldReplace ? "splice" : "push"].call(
      articles,
      ...(shouldReplace ? [index, 1, preview] : [preview]),
    );
    if (isMultipleArticles) {
      let i = 1;
      while (i < 3) {
        articles.push({
          ...preview,
          componentProps: {
            ...preview.componentProps,
            index: i,
          },
        });
        i += 1;
      }
    }
    meta.articles = articles;
    game.meta = meta;
    acc.push(game);
    return acc;
  }, []);
  original.set({
    // @ts-ignore - type assertion doesn't fix this and types can't be inherited through a reducer :woozy:
    gamesList: gamesListNext,
  });
}

export function teardown() {
  original.restore();
  for (let i = 0; i < routes.length; i += 1) {
    const j = routes[i];
    if (typeof j.path !== "string" || !originalRoutes[j.path]) continue;
    routes[i] = originalRoutes[j.path];
    delete originalRoutes[j.path];
  }
}
